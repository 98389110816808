.pulse {
    animation: pulse-animation 1s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px blue;
    }
    100% {
      box-shadow: 0 0 0 10px rgba(59, 19, 237, 0);
    }
  }