
input.MuiInputBase-input.css-yz9k0d-MuiInputBase-input{
    width: 847px;
    padding-left: 20px;
    font-size: 14;
}
.dataResult {
    margin-top: 5px;
    width:55vw;
    height: auto;
    margin-left: 50px;
    background-color: white;
    overflow: hidden;
    overflow-y: auto;
}
input.MuiInputBase-input.css-yz9k0d-MuiInputBase-input:focus {
    outline: none;
    border: none;
    
}

.dataResult::-webkit-scrollbar {
    display: none;
}

.dataResult .dataItem {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    color: black;
}

.dataItem p {
    margin-left: 10px;
}

a {
    text-decoration: none;
}

a:hover {
    background-color: lightgrey;
}

#clearBtn {
    /* cursor: none */ 
}
 

datalist {
    position: absolute;
    max-height: 20em;
    border: 0 none;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  datalist option {
    font-size: 0.8em;
    padding: 0.3em 1em;
    background-color: #ccc;
    /* cursor: none */ 
  }
  
  /* option active styles */
  datalist option:hover, datalist option:focus {
    color: #fff;
    background-color: #036;
    outline: 0 none;
  }

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}