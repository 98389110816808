body {
  margin: 0;
  background: #fafafa;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #333;

  position: absolute;
  height: 100%;
  width: 100%;
  min-height: 800px;
}

#title {
  width: 900px;
  min-height: 18px;
  margin: 10px auto;
  text-align: center;
  font-size: 16px;
  color: #E2E2E2;
  font-weight: 400;
}

#title:hover {
  color: #777;
}

#viewer.spreads {
  width: 461px;
  height: 571px;
  /* box-shadow: 0 0 4px #ccc; */
  border-radius: 5px;
  padding: 0;
  /* position: relative; */
  /* margin: 10px auto; */
  top: calc(50vh - 400px);
}

#viewer.spreads .epub-view>iframe {
  background: white;
}

#viewer.scrolled {
  overflow: hidden;
  width: 800px;
  margin: 0 auto;
  box-shadow: 0 0 4px #ccc;
  padding: 20px;
  background: white;
}

#viewer.scrolled .epub-view>iframe {
  background: white;
}

#prev {
  left: 339;
}

#next {
  right: 0;
}

#toc {
  display: block;
  margin: 25px auto;
}

@media (min-width: 1000px) {
  #viewer.spreads:after {
    position: absolute;
    width: 1px;
    border-right: 1px #000 solid;
    height: 100%;
    z-index: 1;
    left: 50%;
    margin-left: -1px;
    top: 5%;
    opacity: .15;
    box-shadow: -2px 0 15px rgba(0, 0, 0, 1);
    content: "";
  }

  #viewer.spreads.single:after {
    display: none;
  }

  #prev {
    left: 360px;
  }

  #next {
    right: 40px;
  }

}

.arrow {
  position: fixed;
  top: 50%;
  margin-top: -32px;
  font-size: 64px;
  color: #E2E2E2;
  font-family: arial, sans-serif;
  font-weight: bold;
  /* cursor: none */ 
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  text-decoration: none;
}

.navlink {
  margin: 14px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: #ccc;
}

.arrow:hover,
.navlink:hover {
  color: #777;
}

.arrow:active,
.navlink:hover {
  color: #000;
}

#book-wrapper {
  width: 480px;
  height: 640px;
  overflow: hidden;
  border: 1px solid #ccc;
  margin: 28px auto;
  background: #fff;
  border-radius: 0 5px 5px 0;
  position: absolute;
}

#book-viewer {
  width: 480px;
  height: 660px;
  margin: -30px auto;
  -moz-box-shadow: inset 10px 0 20px rgba(0, 0, 0, .1);
  -webkit-box-shadow: inset 10px 0 20px rgba(0, 0, 0, .1);
  box-shadow: inset 10px 0 20px rgba(0, 0, 0, .1);
}

#book-viewer iframe {
  padding: 40px 40px;
}

#controls {
  position: absolute;
  bottom: 16px;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  text-align: center;
  display: none;
}

#controls>input[type=range] {
  width: 400px;
}

#navigation {
  width: 400px;
  height: 100vh;
  position: absolute;
  overflow: auto;
  top: 0;
  left: 0;
  background: #777;
  -webkit-transition: -webkit-transform .25s ease-out;
  -moz-transition: -moz-transform .25s ease-out;
  -ms-transition: -moz-transform .25s ease-out;
  transition: transform .25s ease-out;

}

#navigation.fixed {
  position: fixed;
}

#navigation h1 {
  width: 200px;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  margin-bottom: 10px;
}

#navigation h2 {
  font-size: 14px;
  font-weight: normal;
  color: #B0B0B0;
  margin-bottom: 20px;
}

#navigation ul {
  padding-left: 36px;
  margin-left: 0;
  margin-top: 12px;
  margin-bottom: 12px;
  width: 340px;
}

#navigation ul li {
  list-style: decimal;
  margin-bottom: 10px;
  color: #cccddd;
  font-size: 12px;
  padding-left: 0;
  margin-left: 0;
}

#navigation ul li a {
  color: #ccc;
  text-decoration: none;
}

#navigation ul li a:hover {
  color: #fff;
  text-decoration: underline;
}

#navigation ul li a.active {
  color: #fff;
}

#navigation #cover {
  display: block;
  margin: 24px auto;
}

#navigation #closer {
  position: absolute;
  top: 0;
  right: 0;
  padding: 12px;
  color: #cccddd;
  width: 24px;
}

#navigation.closed {
  -webkit-transform: translate(-400px, 0);
  -moz-transform: translate(-400px, 0);
  -ms-transform: translate(-400px, 0);
  transform: translate(-400px, 0);
}

svg {
  display: block;
}

.close-x {
  stroke: #cccddd;
  fill: transparent;
  stroke-linecap: round;
  stroke-width: 5;
}

.close-x:hover {
  stroke: #fff;
}

#opener {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  stroke: #E2E2E2;
  fill: #E2E2E2;

}

#opener:hover {
  stroke: #777;
  fill: #777;
}


#mobileButtons{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}