.largeInput {
  font-size: 18px;
  padding: 12px;
}
.list {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  max-width: 20rem;
  justify-items: start;
}
.list .mantine-combobox-option:hover {
  background-color: #f0f0f0;
}
.input {
}
