.book_description {
    opacity: 0;
}

.book_read_button {
    opacity: 0;
}

.book_out:hover {
    background-color: #EFEFFD;


}

.book_out:hover .book_desc_button {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
}

.book_out:hover .book_description {
    opacity: 1;


}

.book_out:hover .book_read_button {
    opacity: 1;
    /* cursor: none */ 
}


.fpress_img{
    border: none;
}

.fpress_img:hover {
    border: 2px solid purple;
}

.bookdescription_3{
    border: none;
}

.bookdescription_3:hover{
    border: 2px solid purple;
}